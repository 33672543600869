import React from "react";
import SEO from "@src/components/SEO";
import { useRedirectHandler } from "@src/utils/useRedirectHandler";

function parseQueryString(qs: string): { [key: string]: string } {
  if (qs[0] === "?") qs = qs.slice(1);
  const params = {};
  qs.split("&").forEach(function (query) {
    const parts = query.split("=");
    params[parts[0]] = parts[1];
  });
  return params;
}

const ClassCodeStudentLoginRedirect = () => {
  useRedirectHandler(() => {
    let webURL = "https://www.classdojo.com/studentstories";

    const query = parseQueryString(location.search);
    const token = query.q;

    if (!token) {
      return webURL;
    }

    webURL = "https://student.classdojo.com/#/tokenLoginV2?token=" + token;

    return webURL;
  });

  return (
    <>
      <SEO noindex={true} />
      <div>Redirecting...</div>
    </>
  );
};

export default ClassCodeStudentLoginRedirect;
